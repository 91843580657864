@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--gradient-shadow: linear-gradient(
		70deg,
		#2B7FFF,
		#FFA600
	);
}
body {
  margin: 0;
  font-family: 'Manrope', Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0B0D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-apperance: none;
  appearance: none;
  margin: 0;
}

.emoji {
  font-family: sans-serif;
}


@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

.pool-card {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
  	border-radius: 12px;
	animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.pool-card:before,
.pool-card:after {
	content: "";
	position: absolute;
	top: -1px;
	left: -1px;
	background: var(--gradient-shadow);
	background-size: 400%;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
	z-index: -1;
	animation: animate 15s linear infinite;
	border-radius: 12px;
	opacity: 0.2;

}

.pool-card:after {
	filter: blur(64px);
}

@keyframes animate {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 300% 0;
	}
	100% {
		background-position: 0 0;
	}
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.hero-text {
	background: linear-gradient(45deg,#f83a3a 10%,#f13dd4 50%,#7000ff 90%);
	-webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feat-card {
	background: #17171A;
	border-radius: 16px;
	padding: 40px 24px;
}

.feat-icon {
	padding: 16px;
	width: 80px;
	height: 80px;
	border-radius: 16px;
	background: linear-gradient(180deg, rgba(16, 16, 18, 0) -64.52%, #131314 100%);
}
